import React from 'react';
import {
  BreakpointQueryName,
  Button,
  Image,
  Logo,
  Modal,
  Portal,
  useModal,
} from '@doveit/lego';
import { useInViewEffect } from 'react-hook-inview';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components';
import { requireFromStaticAsset } from '../../utils/assetsUtils';
import * as styles from './LeadInvitationPopup.style';
import { buildEvaluationURLObject, goToPage } from '../../utils/links';
import useSessionStorage from '../../hooks/useSessionStorage/useSessionStorage';

export const LEAD_INVITATION_POPUP_SHOWN_SESSION_KEY = 'lead-invitation-popup-shown';

interface Trigger {
  element: React.ReactNode,
  when?: 'in-view' | 'out-of-view',
  options?: IntersectionObserverInit,
}

export interface LeadInvitationPopupProps {
  trigger: Trigger,
}

const LeadInvitationPopup: React.FC<LeadInvitationPopupProps> = ({
  trigger,
}) => {
  const {
    element,
    options,
    when = 'in-view',
  } = trigger;

  const [alreadyShown, setShown] = useSessionStorage(LEAD_INVITATION_POPUP_SHOWN_SESSION_KEY, false);
  const theme = useTheme();
  const isTabletOrBigger = useMediaQuery(theme.breakpoints.up(BreakpointQueryName.LG));
  const popup = useModal();

  /**
   * @todo consider switching from `react-hook-inview` to `react-intersection-observer`
   */
  const triggerRef = useInViewEffect(
    ([entry], observer) => {
      const shouldShow = when === 'in-view' ? entry.isIntersecting : !entry.isIntersecting;

      if (shouldShow && !alreadyShown && isTabletOrBigger) {
        popup.open();
        setShown(true);
      }

      return () => {
        observer.unobserve(entry.target);
      };
    },
    options,
    [alreadyShown, isTabletOrBigger],
  );

  React.useEffect(() => {
    if (popup.isOpen && !isTabletOrBigger) {
      popup.close();
    }
  }, [isTabletOrBigger, popup]);

  const onButtonClick = React.useCallback(() => {
    popup.close();

    goToPage(buildEvaluationURLObject());
  }, [popup]);

  return (
    <>
      <div ref={triggerRef}>
        {element}
      </div>
      <Portal>
        <Modal
          open={popup.isOpen}
          onCloseHandler={popup.close}
          aria-label="Invito nuova valutazione"
        >
          <Modal.Close />
          <Modal.Body>
            <Image
              src={requireFromStaticAsset('lead-invitation-background.jpg')}
              alt="Valuta online la tua casa"
            />
            <div css={styles.info}>
              <div css={styles.trapezoid}>
                <div css={styles.triangle} />
                <div css={styles.content}>
                  <div css={styles.logo}>
                    <Logo />
                  </div>
                  <strong css={styles.headline}>
                    Scopri subito il valore della tua casa
                  </strong>
                  <p css={styles.text}>
                    Ricevi una valutazione gratuita e vendi casa a<br />
                    <strong>ZERO COMMISSIONI</strong> con i <strong>migliori agenti immobiliari</strong>
                  </p>
                </div>
              </div>
              <div css={styles.button}>
                <Button
                  label="Valuta online la tua casa"
                  title="Valuta online la tua casa"
                  aria-label="Valuta online la tua casa"
                  onClick={onButtonClick}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Portal>
    </>
  );
};

export default LeadInvitationPopup;
