import {
  SpacingName,
  ThemedFunctor, TypographyName, getSpacing, getTypography,
} from '@doveit/lego';
import { css } from 'styled-components';

export const info: ThemedFunctor = () => css`
  max-width: 27.5rem;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`;

export const trapezoid: ThemedFunctor = () => css`
  position: relative;
  padding-left: 2.5rem;
`;

export const triangle: ThemedFunctor = ({ theme }) => css`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 2.5rem;
  content: url(${`"data:image/svg+xml,%3Csvg height='100%25' preserveAspectRatio='none' viewBox='0 0 72 154' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m72 0v154h-72z' fill='%23${theme.palettes.brand.primary.replace('#', '')}' fill-rule='evenodd'/%3E%3C/svg%3E"`});
`;

export const content: ThemedFunctor = ({ theme }) => css`
  padding: 1.5rem 1.5rem 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: ${theme.palettes.brand.primary};
  color: ${theme.palettes.neutrals.lightest};
`;

export const button: ThemedFunctor = ({ theme }) => css`
  ${getSpacing.marginTop(theme, SpacingName.MEDIUM)}
  display: flex;
  padding-right: 2rem;
  justify-content: flex-end;
`;

export const logo: ThemedFunctor = () => css`
  width: 7.5rem;
  align-self: flex-end;
`;

export const headline: ThemedFunctor = ({ theme }) => css`
  ${getTypography(theme, TypographyName.H3)};
  text-transform: uppercase;
`;

export const text: ThemedFunctor = ({ theme }) => css`
  ${getTypography(theme, TypographyName.SMALL)};
`;
