import { SpacingName } from '@doveit/lego';
import React, { FunctionComponent } from 'react';
import LogoList, { LogoListProps } from '../../../components/logo-list/LogoList';
import PageSection from '../../../components/page-section/PageSection';
import { TextAlign } from '../../../types';
import { requireFromStaticAsset } from '../../../utils/assetsUtils';

export interface LogosSectionProps {
  title?: string,
  description?: string,
  items: LogoListProps['items'],
  inlineItems?: LogoListProps['inlineItems'],
}

const LogosSection: FunctionComponent<LogosSectionProps> = ({
  title,
  description,
  items,
  inlineItems,
}) => (
  <PageSection padding={!title ? [0, 0, SpacingName.BIGGER] : undefined}>
    <PageSection.Wrapper>
      {title && (
        <PageSection.Title textAlign={TextAlign.CENTER}>
          {title}
        </PageSection.Title>
      )}
      {description && (
        <PageSection.Description
          text={description}
          textAlign={TextAlign.CENTER}
        />
      )}
      <PageSection.Block>
        <LogoList
          items={items.map((item) => ({
            ...item,
            picture: requireFromStaticAsset(item.picture),
          }))}
          inlineItems={inlineItems}
        />
      </PageSection.Block>
    </PageSection.Wrapper>
  </PageSection>
);

export default LogosSection;
