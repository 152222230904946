import { useCallback, useEffect, useState } from 'react';

export default function useSessionStorage(keyName, defaultValue) {
  const [storedValue, setStoredValue] = useState();

  useEffect(() => {
    const value = window.sessionStorage.getItem(keyName);
    setStoredValue(value ? JSON.parse(value) : defaultValue);
  }, [defaultValue, keyName]);

  const setValue = useCallback((newValue) => {
    window.sessionStorage.setItem(keyName, JSON.stringify(newValue));
    setStoredValue(newValue);
  }, [keyName]);

  return [storedValue, setValue];
}
